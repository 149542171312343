import React from 'react';
import { ExternalLinkIcon } from '@stashinvest/ui';
import {
	cardManagementVirtualCard,
	lockUnlockAccountManagement,
	trackAtmLocatorCTA,
} from '../../events/cardManagement';
import { BanjoString } from '@stashinvest/react-banjo';
import { useBankAccount } from '../../selectors';
import { DisabledFeaturesList } from '../../routes/BankRoute';
import { recurringTransferScreenLink } from '../recurring-transfers/recurringTransferMixpanel';
import { trackSettingsMenuDataSharingClick } from '../bank-link/mixpanel';

export const ExternalIcon = () => (
	<ExternalLinkIcon verticalAlign="middle" size="1.1em" />
);

export const BankPages = [
	{
		title: 'Manage card',
		pathname: `/debit/cardManagement`,
		showInArchive: false,
		showInContentMoat: false,
		onClick: lockUnlockAccountManagement,
	},
	{
		title: 'Temporary Virtual Card',
		pathname: `/debit/settings/virtualCard`,
		showInArchive: false,
		showInContentMoat: false,
		onClick: cardManagementVirtualCard,
		disallowed: DisabledFeaturesList.VirtualCard,
	},
	{
		title: <BanjoString stringId="management/function/autoAddCash" />,
		pathname: `/debit/recurringTransfers`,
		showInArchive: false,
		showInContentMoat: false,
		onClick: recurringTransferScreenLink,
		disallowed: DisabledFeaturesList.RecurringTransfer,
	},
	{
		title: 'ATM Locator',
		showInArchive: false,
		showInContentMoat: false,
		pathname: `https://www.stash.com/atm-locator`,
		onClick: trackAtmLocatorCTA,
		icon: ExternalIcon,
		disallowed: DisabledFeaturesList.AtmLocator,
	},
	{
		title: 'Account & Routing numbers',
		showInArchive: false,
		pathname: `/debit/accountInformation`,
		disallowed: DisabledFeaturesList.AccountInfo,
	},
];

export const contentMoatPage = {
	title: 'Investment and financial advice',
	pathname: `/subscriber-advice`,
	activeMatches: [],
	showInArchive: false,
	showInContentMoat: false,
};

export const getSettingsPages = ({
	archive,
	isContentMoat,
	disabledFeatures,
	hasAdvisorySignature,
	isPaywalled,
	isInPlanDetailsPostCancelVariation,
}) => {
	const pages = [
		{
			title: 'Your profile',
			pathname: `/account-settings`,
			activeMatches: ['/investor-profile'],
			showInArchive: false,
			showInContentMoat: false,
		},
		{
			title: 'Market insights report',
			pathname: `/market-insights-report`,
			activeMatches: [],
			showInArchive: false,
			showInContentMoat: true,
		},
		{
			title: 'Refer friends to earn cash',
			pathname: `${archive}/referrals`,
			activeMatches: [],
			showInArchive: false,
			showInContentMoat: false,
		},
		{
			title: 'Enter promo code',
			pathname: `/promotion-code`,
			activeMatches: [],
			showInArchive: false,
			showInContentMoat: false,
		},
		{
			title: 'Email, phone & security',
			pathname: `/security`,
			activeMatches: [],
			showInArchive: false,
			showInContentMoat: false,
		},
		{
			title: 'Portfolio activity',
			pathname: `${archive}/activity`,
			activeMatches: [],
			showInArchive: true,
			showInContentMoat: false,
		},
		{
			title: 'Statements & tax documents',
			pathname: `${archive}/documents`,
			activeMatches: [
				'/statements',
				'/trade-confirmations',
				'/tax-documents',
				'/closed-account-documents',
			],
			showInArchive: true,
			showInContentMoat: false,
		},
		{
			title: 'Securities lending',
			pathname: `/investments_lending`,
			activeMatches: [],
			showInArchive: false,
			showInContentMoat: false,
		},
		{
			title: 'Dividend reinvestment program',
			pathname: `/dividend-reinvestment-program`,
			activeMatches: [],
			showInArchive: false,
			showInContentMoat: false,
		},
		{
			title: 'External bank account',
			pathname: `${archive}/manage-bank-accounts`,
			activeMatches: ['/manage-bank-account', '/manage-bank-accounts'],
			showInArchive: false,
			showInContentMoat: false,
		},
		{
			title: 'Manage plan',
			pathname: `${archive}/plan-details`,
			activeMatches: ['/manage-subscription', '/add-payment-method'],
			showInArchive: false,
			showInContentMoat: true,
		},
		hasAdvisorySignature && !isPaywalled && contentMoatPage,
		{
			title: 'Notification Preferences',
			pathname: '/notification-settings',
			activeMatches: [],
			archived: false,
			showInContentMoat: false,
		},
		{
			title: 'Accessibility',
			pathname: '/accessibility-settings',
			activeMatches: [],
			archived: false,
			showInContentMoat: true,
		},
		{
			title: 'Data sharing',
			pathname: '/data-sharing',
			activeMatches: [],
			archived: false,
			showInContentMoat: true,
			onClick: trackSettingsMenuDataSharingClick,
		},
		disabledFeatures && BankPages[0],
		disabledFeatures && BankPages[1],
		disabledFeatures && BankPages[2],
		disabledFeatures && BankPages[3],
		disabledFeatures && BankPages[4],
	].filter((x) => x);

	if (isInPlanDetailsPostCancelVariation) {
		pages.forEach((page) => {
			if (page.pathname === '/archive/plan-details') {
				page.showInArchive = true;
			}
			return page;
		});
	}

	const filterIfArchive = (p) => !archive || p.showInArchive;

	if (isContentMoat) {
		return pages.filter((p) => p.showInContentMoat);
	}

	if (disabledFeatures) {
		const disallowed = (p) => !disabledFeatures.includes(p.disallowed);
		return pages.filter(disallowed).filter((p) => filterIfArchive(p));
	}

	return pages.filter((p) => filterIfArchive(p));
};

export const useBankSettingsPages = ({ archive }) => {
	const account = useBankAccount();
	const filterIfArchive = (p) => !archive || p.showInArchive;
	const disallowed = (p) => !account.disabledFeatures.includes(p.disallowed);
	return BankPages.filter(disallowed).filter((p) => filterIfArchive(p));
};
