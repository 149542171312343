import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorMessageComponent from './ErrorMessageComponent';

const mapStateToProps = (state, ownProps) => ({
	user_uuid: state.entities.user.uuid,
});

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, error_info) {
		if (error && error.name === 'ChunkLoadError') {
			console.error('Chunk reloaded');
			return window.location.reload();
		}
	}

	render() {
		if (this.state.hasError) {
			return <ErrorMessageComponent />;
		}

		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
	user_uuid: PropTypes.string,
	location: PropTypes.object,
};

export default connect(mapStateToProps)(ErrorBoundary);
